export interface IStripePlanConfig {
    name: string;
    planId: string;
    cost: number;
}

export interface IStripeKeys {
    publicKey: string;
    clientId: string;
}

export enum ESubscriptionType {
    monthly = 'MONTHLY',
    yearly = 'YEARLY'
}

export interface IStripeConfig {
    [key: string]: {
        publicKeys: IStripeKeys,
        paymentPlans: { [key: string]: IStripePlanConfig }
    }
}

export const StripeConfig: IStripeConfig = {
    'development': {
        publicKeys: {
            publicKey: '',
            clientId: ''
        },
        paymentPlans: {
            'MONTHLY': {
                name: 'monthly',
                planId: 'monthlyPlanId',
                cost: 999
            },
            'YEARLY': {
                name: 'yearly',
                planId: 'yearlyPlanId',
                cost: 9999
            }
        }
    }
}