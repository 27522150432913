"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EZenChatMessageRoles = exports.ENotificationType = exports.ENotificationFrequency = exports.EZenGoalSuggestionStatus = exports.ETaskPriority = exports.EActiveStatus = exports.ETaskStatus = exports.ELoginError = void 0;
// *** Important. 'export const enum' will compile but fail at runtime ***
var ELoginError;
(function (ELoginError) {
    ELoginError["USER_NOT_FOUND"] = "USER_NOT_FOUND";
    ELoginError["PASSWORD_INCORRECT"] = "PASSWORD_INCORRECT";
    ELoginError["USER_ALREADY_REGISTERED"] = "USER_ALREADY_REGISTERED";
    ELoginError["SYSTEM_ERROR"] = "SYSTEM_ERROR";
})(ELoginError = exports.ELoginError || (exports.ELoginError = {}));
var ETaskStatus;
(function (ETaskStatus) {
    ETaskStatus["inbox"] = "inbox";
    ETaskStatus["thisWeek"] = "thisWeek";
    ETaskStatus["today"] = "today";
    ETaskStatus["done"] = "done";
})(ETaskStatus = exports.ETaskStatus || (exports.ETaskStatus = {}));
var EActiveStatus;
(function (EActiveStatus) {
    EActiveStatus["active"] = "ACTIVE";
    EActiveStatus["deleted"] = "DELETED"; // Archived
})(EActiveStatus = exports.EActiveStatus || (exports.EActiveStatus = {}));
var ETaskPriority;
(function (ETaskPriority) {
    ETaskPriority["high"] = "HIGH";
    ETaskPriority["normal"] = "NORMAL";
})(ETaskPriority = exports.ETaskPriority || (exports.ETaskPriority = {}));
var EZenGoalSuggestionStatus;
(function (EZenGoalSuggestionStatus) {
    EZenGoalSuggestionStatus["active"] = "ACTIVE";
    EZenGoalSuggestionStatus["deleted"] = "DELETED";
})(EZenGoalSuggestionStatus = exports.EZenGoalSuggestionStatus || (exports.EZenGoalSuggestionStatus = {}));
// Notifications
// There will be a notifications section in the UI for notification configuration in the future. For now everyone gets a
// status update at 6am on weekdays
var ENotificationFrequency;
(function (ENotificationFrequency) {
    ENotificationFrequency[ENotificationFrequency["daily"] = 0] = "daily";
    ENotificationFrequency[ENotificationFrequency["weekdays"] = 1] = "weekdays";
    ENotificationFrequency[ENotificationFrequency["weekly"] = 2] = "weekly";
})(ENotificationFrequency = exports.ENotificationFrequency || (exports.ENotificationFrequency = {}));
var ENotificationType;
(function (ENotificationType) {
    ENotificationType["statusUpdate"] = "STATUS_UPDATE";
})(ENotificationType = exports.ENotificationType || (exports.ENotificationType = {}));
var EZenChatMessageRoles;
(function (EZenChatMessageRoles) {
    EZenChatMessageRoles["system"] = "system";
    EZenChatMessageRoles["user"] = "user";
    EZenChatMessageRoles["assistant"] = "assistant";
})(EZenChatMessageRoles = exports.EZenChatMessageRoles || (exports.EZenChatMessageRoles = {}));
