import React, {createContext, ReactNode, useEffect, useState} from "react";
import {ManageBearerToken} from "../lib/ManageBearerToken";
import {useLocation, useNavigate} from "react-router-dom";


const tokenManager = new ManageBearerToken()

const nonAuthenticatedRoutes = [
    '/login',
    '/register'
]

// A subset of IUser for use in the client
export interface IClientUser {
    _id: string,
    firstname: string,
    lastname: string,
    email: string,
    timezone: string,
}

export interface IAuthContext {
    user: IClientUser | null;
    loggedIn: boolean;
    doLogin: (() => void) | null;
    doLogout: (() => void) | null;
}

export const AuthContext: React.Context<IAuthContext> = createContext<IAuthContext>(
    {
        user: null,
        loggedIn: false,
        doLogin: null,
        doLogout: null
    }
)

interface Props {
    children: ReactNode
}

const Authenticate = ({children}: Props) => {

    const [user, setUser] = useState<IClientUser | null>(null);
    const [loggedIn, setLoggedIn] = useState<boolean>(false);
    const [authComplete, setAuthComplete] = useState<boolean>(false);

    const location = useLocation();
    const navigate = useNavigate();

    const doLogout = () => {

        setUser(null);
        tokenManager.deleteToken();
        setLoggedIn(false);
        navigate('/login');
    }

    const doLogin = () => {

        const tokenValid = tokenManager.tokenValid();

        if (!tokenValid) {

            setLoggedIn(false);
            setUser(null);
            setAuthComplete(true);
            return navigate('/login');
        }

        const userData = tokenManager.getUser()
        setLoggedIn(true);
        setUser(userData)
        setAuthComplete(true);
    }

    useEffect(
        () => {
            doLogin();
        }, []
    )

    useEffect(
        () => {

            if (authComplete && loggedIn && nonAuthenticatedRoutes.indexOf(location.pathname) > -1) {
                navigate('/')
            }

        }, [loggedIn, authComplete]
    )

    if(!authComplete) return null;

    return <AuthContext.Provider value={{user, loggedIn, doLogout, doLogin}}>{children}</AuthContext.Provider>;

}

export default Authenticate
