interface IConfigValues {
    [key: string]: { apiServer: string; }
}

const config: IConfigValues = {

    development: {
        apiServer: 'http://localhost:3030'
    },
    production: {
        apiServer: 'https://api.goalzen.ai'
    }

};

const env = process.env.NODE_ENV;

if (!env) throw new Error('Cannot determine environment');


export default config[env]

