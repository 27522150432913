import React, {useContext, useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {EAlertType, GlobalAlertContext} from "../../components/GlobalAlert";
import ApiClient, {IApiRejectReason} from "../../lib/ApiClient";
import {AuthContext} from "../../components/Authenticate";
import {CredentialResponse, GoogleLogin} from "@react-oauth/google";
import {ManageBearerToken} from "../../lib/ManageBearerToken";
import {useTranslation} from "react-i18next";
import {ELoginError, IUser} from "@yellowmelon/zen-global-types";

const apiClient = new ApiClient();

interface IFieldValidation {
    valid: boolean;
    dirty: boolean;
}

interface ILoginValidation {
    email: IFieldValidation;
    password: IFieldValidation;
}

interface ILoginForm {
    email: string;
    password: string
}

const tokenManager = new ManageBearerToken();

const Login = () => {

    const [loading, setLoading] = useState<boolean>(false);

    const [loginForm, setLoginForm] = useState<ILoginForm>({
        email: '',
        password: '',
    })

    const [validation, setValidation] = useState<ILoginValidation>(
        {
            email: {valid: true, dirty: false},
            password: {valid: true, dirty: false},
        }
    )

    const [formValid, setFormValid] = useState<boolean>(true);
    const {showAlert} = useContext(GlobalAlertContext);
    const {doLogin, loggedIn} = useContext(AuthContext)
    const {t} = useTranslation();

    useEffect(
        () => {

            const keys: string[] = Object.keys(validation);
            let valid = true;

            keys.forEach(
                (key: string) => {

                    // @ts-ignore
                    if (!validation[key].valid) {
                        valid = false;
                    }

                }
            )

            setFormValid(valid);


        }, [validation]
    )

    const validateForm = () => {

        const emailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(loginForm.email);
        const passwordValid = !!loginForm.password?.length;

        setValidation({
            ...validation,
            email: {valid: emailValid, dirty: true},
            password: {valid: passwordValid, dirty: true}
        });


        return {
            emailValid, passwordValid
        }


    }

    const handleInput = (field: string, value: string | boolean) => {

        switch(field){  // Reset validation when user types

            case 'email':
                setValidation({...validation, email: { valid: true, dirty: false }})
                break;

            case 'password':
                setValidation({...validation, password: { valid: true, dirty: false }})
                break;

        }

        setLoginForm({...loginForm, [field]: value})

    }

    const submit = (ev: any) => {

        ev.preventDefault();

        const { emailValid, passwordValid } = validateForm();

        if(!emailValid || !passwordValid) return;

        setLoading(true);

        apiClient.post<{ user: IUser, token: string }>(`auth/v1/login`, {payload: {user: {...loginForm}}}).then(
            ({user, token}) => {

                setLoading(false);
                tokenManager.setToken(token);
                doLogin && doLogin() // Auth manager will redirect

            }
        ).catch(
            (err: IApiRejectReason) => {

                console.error(err)
                let errorMessage = ''

                switch (err.reason) {

                    case ELoginError.USER_NOT_FOUND:

                        errorMessage = 'Sorry but this email address was not found. Please try a new email address or register a new one.'
                        break;

                    case ELoginError.PASSWORD_INCORRECT:
                        errorMessage = 'Sorry but this password is incorrect';
                        break;

                    default:

                        errorMessage = `An unexpected error has occurred: ${err?.message}`

                }

                setLoading(false);
                showAlert(EAlertType.danger, errorMessage, {label: 'Ok'})

            }
        )

    };

    const googleLogin = async (response: CredentialResponse) => {

        const credential = response.credential;

        if (!credential) {
            return showAlert(EAlertType.danger, 'Sorry but an error occurred: could not retrieve Google credential', {label: 'Ok'})
        }

        // Get user timezone from their browser
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        try {

            setLoading(true);

            const {token} = await apiClient.post<{
                user: IUser,
                token: string
            }>('auth/v1/googlelogin', {payload: {googleToken: credential, timezone}})

            setLoading(false);
            tokenManager.setToken(token);

            doLogin && doLogin() // Auth manager will redirect


        } catch (err: any) {

            showAlert(EAlertType.danger, `Sorry but an error occurred with this request: ${err.message}`, {label: 'Ok'})

        }

    }

    return (
        <div className='page-content register-page p-2'>

            <div className='md:w-1/2 md:mx-auto'>

                <div
                    className="mt-7 bg-white border border-gray-200 rounded-xl shadow-sm dark:bg-gray-800 dark:border-gray-700">
                    <div className="p-4 sm:p-7">
                        <div className="text-center">
                            <h1 className="block text-2xl font-bold text-gray-800 dark:text-white">{t('login.title')}</h1>
                            <p className="mt-2 text-sm text-gray-600 dark:text-gray-400 mb-4">
                                Don't have an account?
                            </p>
                            <Link to='/register' className="btn btn-primary btn-small font-medium">
                                Sign up here
                            </Link>
                        </div>

                        <div className="mt-5">


                            <GoogleLogin onSuccess={googleLogin} onError={() => {
                                showAlert(EAlertType.danger, `Sorry but an error occurred: Could not authorize login with Google`)
                            }}/>

                            <div
                                className="py-3 flex items-center text-xs text-gray-400 uppercase before:flex-[1_1_0%] before:border-t before:border-gray-200 before:mr-6 after:flex-[1_1_0%] after:border-t after:border-gray-200 after:ml-6 dark:text-gray-500 dark:before:border-gray-600 dark:after:border-gray-600">Or
                            </div>

                            <form className='preline-form' onSubmit={(ev) => {
                                submit(ev)
                            }}>

                                <div className="grid gap-y-4">

                                    <div>

                                        <label htmlFor="email" className="block text-sm mb-2 dark:text-white">Email
                                            address<sup>*</sup></label>

                                        <div className="relative">

                                            <input type="email"
                                                   name={'email'}
                                                   id="email"
                                                   onInput={(event) => {
                                                       const inputElement = event.target as HTMLInputElement;
                                                       handleInput('email', inputElement.value);
                                                   }}
                                                   className="text-input"
                                                   aria-describedby="email-error"/>
                                            <div
                                                className="hidden absolute inset-y-0 right-0 flex items-center pointer-events-none pr-3">
                                                <svg className="h-5 w-5 text-red-500" width="16" height="16"
                                                     fill="currentColor"
                                                     viewBox="0 0 16 16" aria-hidden="true">
                                                    <path
                                                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                                                </svg>
                                            </div>
                                        </div>
                                        {!validation.email.valid &&
                                            <p className="text-xs bg-red-500 text-white mt-2 p-1 text-center rounded-md"
                                               id="email-error">Please enter a valid email address</p>}
                                    </div>

                                    <div>
                                        <label htmlFor="password"
                                               className="block text-sm mb-2 dark:text-white">Password</label>
                                        <div className="relative">

                                            <input type="password"
                                                   id="password"
                                                   onInput={(ev) => {
                                                       const inputElement = ev.target as HTMLInputElement;
                                                       handleInput('password', inputElement.value);
                                                   }}
                                                   className="py-3 px-4 block w-full border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400"
                                                   aria-describedby="password-error"/>

                                            <div
                                                className="hidden absolute inset-y-0 right-0 flex items-center pointer-events-none pr-3">
                                                <svg className="h-5 w-5 text-red-500" width="16" height="16"
                                                     fill="currentColor"
                                                     viewBox="0 0 16 16" aria-hidden="true">
                                                    <path
                                                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                                                </svg>
                                            </div>

                                        </div>

                                        {!validation.password.valid &&
                                            <p className="text-xs bg-red-500 text-white mt-2 p-1 text-center rounded-md"
                                               id="password-error">Please enter your password</p>}

                                    </div>

                                    <button type="submit"
                                            disabled={!formValid}
                                            className="py-3 px-4 inline-flex justify-center items-center gap-2 disabled:cursor-not-allowed rounded-md border border-transparent font-semibold bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800">

                                        {loading && <span>please wait...</span>}

                                        {!loading && formValid && <span>Login</span>}

                                        {!formValid && <span>Please complete the form correctly</span>}

                                    </button>

                                </div>

                            </form>

                        </div>

                    </div>

                </div>

            </div>


        </div>
    )

}

export default Login
