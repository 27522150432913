import React, {createContext, useContext, useEffect, useState} from "react";
import KanbanColumn from "./KanbanColumn";
import ApiClient from "../../../lib/ApiClient";
import {ETaskStatus, IZenTask} from "@yellowmelon/zen-global-types";
import {EAlertType, GlobalAlertContext} from "../../../components/GlobalAlert";
import ManageContext from "./ManageContext";
import {GlobalContext} from "../../../lib/GlobalContextProvider";
import FilterKanbanTasks from "./FilterKanbanTasks";
import * as querystring from "querystring";

interface IKanbanBoardContext {
    getTaskList: (contextId? : string, filterToday?: boolean) => void

}

export const DraggableItemTypes = {
    TASK: 'task'
}

export const KanbanBoardContext = createContext<IKanbanBoardContext>(
    {
        getTaskList: () => {}
    }
)

const KanbanBoard = () => {

    const apiClient = new ApiClient();
    const [taskList, setTaskList] = useState<IZenTask[]>([])
    const {showAlert} = useContext(GlobalAlertContext);
    const {currentContext, filterToday} = useContext(GlobalContext)
    const [filterPriority, setFilterPriority] = useState<boolean>(false);

    const getTaskList = () => {

        const getContextId = currentContext?._id ? currentContext?._id.toString() : '';
        const queryObject: {[key:string] : string} = {};

        if (getContextId){
            queryObject['context'] = getContextId;

            if(filterToday){
                queryObject['filtertoday'] = 'true'
            }

        }

        if(filterPriority){
            queryObject['filterpriority'] = 'true'
        }

        const queryString = querystring.stringify(queryObject);

        apiClient.get<{ tasks: IZenTask[] }>(`api/v1/tasks${ !!queryString ? `?${queryString}` : '' }`).then(
            ({tasks}) => {

                setTaskList(tasks)

            }
        ).catch(
            (err: any) => {

                console.error(err.message);
                showAlert(EAlertType.danger, `Sorry but an error occurred: ${err.message}`);

            }
        )

    }

    useEffect(
        () => {

            getTaskList();

        }, [currentContext, filterToday, filterPriority]
    )


    return (
        <KanbanBoardContext.Provider value={{getTaskList}}>

            <div className='kanban-container'>

                <div className='task-management flex items-center mb-8'>

                    <ManageContext />

                    <FilterKanbanTasks className='ml-2' filterPriority={filterPriority} doFilterPriority={ setFilterPriority } />

                </div>

                <div className={'kanban-column-container overflow-x-auto flex pb-10'} style={{scrollSnapType: 'x mandatory'}}>

                    <KanbanColumn
                        key={ETaskStatus.inbox}
                        setTaskList={setTaskList}
                        status={ETaskStatus.inbox}
                        title='Inbox'
                        taskList={taskList}/>

                    <KanbanColumn
                        key={ETaskStatus.thisWeek}
                        setTaskList={setTaskList} status={ETaskStatus.thisWeek}
                        title='This week'
                        taskList={taskList}/>

                    <KanbanColumn
                        key={ETaskStatus.today}
                        setTaskList={setTaskList} status={ETaskStatus.today} title='Today'
                        taskList={taskList}/>

                    <KanbanColumn
                        key={ETaskStatus.done}
                        setTaskList={setTaskList} status={ETaskStatus.done} title='Done'
                        taskList={taskList}
                        showAddButton={false}
                    />

                </div>

            </div>

        </KanbanBoardContext.Provider>
    )

}

export default KanbanBoard
