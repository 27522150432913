import {IClientUser} from "../components/Authenticate";
import jwt_decode from "jwt-decode";

export class ManageBearerToken {

    storageKey: string
    token: string;

    constructor(storageKey = 'zen_token') {

        this.storageKey = storageKey
        this.token = '';

    }

    setToken(token: string) {

        localStorage.setItem(this.storageKey, token)

    }

    getToken() {

        return localStorage.getItem(this.storageKey)

    }

    getUser(): IClientUser | null {

        const token = localStorage.getItem(this.storageKey);

        if (!token) return null

        const decoded: any = jwt_decode(token);
        const userData: IClientUser = decoded?.data?.user;

        return userData;

    }

    tokenValid() {

        const token = localStorage.getItem(this.storageKey);
        if (!token) return false;

        const decoded: any = jwt_decode(token);
        const expiry: number | null = decoded?.exp;

        if (!expiry) return false;

        const tokenValid = (expiry * 1000) > Date.now();

        if (!tokenValid) {
            this.deleteToken();
            return false;
        }

        return true;

    }

    deleteToken() {
        localStorage.removeItem(this.storageKey)
    }

}