import React, {ReactNode} from "react";
import {useLocation} from 'react-router-dom'
import Header from "./Header";
import './layout.less';
import PageTitle from "./PageTitle";

const Layout = ({children}: { children: ReactNode }) => {

  const location = useLocation();

  return (
    <>
      <Header/>

      <PageTitle path={location.pathname}/>

      <section className='content-main px-4 md:px-40 mb-10 overflow-x-auto'>

        <>{children}</>

      </section>

    </>
  )

}

export default Layout
