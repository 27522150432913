import React, {useEffect, useState} from "react";
import {ESubscriptionType, StripeConfig} from "../../../config/stripe";

const environment = process.env.NODE_ENV || 'dev'
console.log(environment);

const Subscription = () => {

    const [subscriptionType, setSubscriptionType] = useState<ESubscriptionType>(ESubscriptionType.monthly)

    useEffect(
        () => {

            console.log(subscriptionType)

        }, [subscriptionType]
    )

    return (
        <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">

            <div className="mx-auto max-w-2xl mb-8 lg:mb-14 text-center">
                <h2 className="text-3xl lg:text-4xl text-gray-800 font-bold dark:text-gray-200">
                    Accomplish your goals with the power of Artificial Intelligence
                </h2>
            </div>


            <div className="relative xl:w-10/12 xl:mx-auto pb-8">

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-8">

                    <div
                        className="p-4 relative z-10 bg-white border rounded-xl md:p-10 dark:bg-slate-900 dark:border-gray-700">
                        <h3 className="text-xl font-bold text-gray-800 dark:text-gray-200">Monthly</h3>

                        <div className="mt-5">
                            <span className="text-6xl font-bold text-gray-800 dark:text-gray-200">{ `$${Math.floor(StripeConfig[environment].paymentPlans[ESubscriptionType.monthly].cost / 100)}` }</span>
                            <span className="text-lg font-bold text-gray-800 dark:text-gray-200">{ `.${StripeConfig[environment].paymentPlans[ESubscriptionType.monthly].cost % 100}` }</span>
                            <span className="ml-3 text-gray-500">USD / monthly</span>
                        </div>

                        <div className="mt-5 grid grid-cols-2 gap-x-4 py-4 first:pt-0 last:pb-0">
                            <div>
                                <p className="text-sm text-gray-500">Cancel anytime</p>
                            </div>

                            <div className="flex justify-end">
                                <button type="button"
                                        className="py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800">Start
                                    free trial
                                </button>
                            </div>
                        </div>
                    </div>

                    <div
                        className="p-4 relative z-10 bg-white border rounded-xl md:p-10 dark:bg-slate-900 dark:border-gray-700">
                        <h3 className="text-xl font-bold text-gray-800 dark:text-gray-200">Yearly</h3>

                        <div className="mt-5">
                            <span className="text-6xl font-bold text-gray-800 dark:text-gray-200">{ process.env.NODE_ENV && `$${Math.floor(StripeConfig[process.env.NODE_ENV].paymentPlans[ESubscriptionType.yearly].cost / 100)}` }</span>
                            <span className="text-lg font-bold text-gray-800 dark:text-gray-200">{ process.env.NODE_ENV && `.${StripeConfig[process.env.NODE_ENV].paymentPlans[ESubscriptionType.yearly].cost % 100}` }</span>
                            <span className="ml-3 text-gray-500">USD / yearly</span>
                        </div>

                        <div className="mt-5 grid grid-cols-2 gap-x-4 py-4 first:pt-0 last:pb-0">
                            <div>
                                <p className="text-sm text-gray-500">Cancel anytime</p>
                            </div>

                            <div className="flex justify-end">
                                <button type="button"
                                        className="py-3 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-400 dark:hover:text-white dark:focus:ring-offset-gray-800">Start
                                    free trial
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="hidden md:block absolute top-0 right-0 translate-y-16 translate-x-16">
                    <svg className="w-16 h-auto text-orange-500" width="121" height="135" viewBox="0 0 121 135"
                         fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5 16.4754C11.7688 27.4499 21.2452 57.3224 5 89.0164" stroke="currentColor"
                              strokeWidth="10" strokeLinecap="round"/>
                        <path d="M33.6761 112.104C44.6984 98.1239 74.2618 57.6776 83.4821 5" stroke="currentColor"
                              strokeWidth="10" strokeLinecap="round"/>
                        <path d="M50.5525 130C68.2064 127.495 110.731 117.541 116 78.0874" stroke="currentColor"
                              strokeWidth="10" strokeLinecap="round"/>
                    </svg>
                </div>

                <div className="hidden md:block absolute bottom-0 left-0 translate-y-16 -translate-x-16">
                    <svg className="w-56 h-auto text-cyan-500" width="347" height="188" viewBox="0 0 347 188"
                         fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M4 82.4591C54.7956 92.8751 30.9771 162.782 68.2065 181.385C112.642 203.59 127.943 78.57 122.161 25.5053C120.504 2.2376 93.4028 -8.11128 89.7468 25.5053C85.8633 61.2125 130.186 199.678 180.982 146.248L214.898 107.02C224.322 95.4118 242.9 79.2851 258.6 107.02C274.299 134.754 299.315 125.589 309.861 117.539L343 93.4426"
                            stroke="currentColor" strokeWidth="7" strokeLinecap="round"/>
                    </svg>
                </div>
            </div>

            <div className={'charging section relative xl:w-10/12 xl:mx-auto py-8 border-t'}>

                <div className={'subscription-toggle flex align-middle justify-center'}>

                    <label className={'pe-2'}>monthly</label>

                    <input
                        type="checkbox"
                        id="hs-basic-usage"
                        onChange={(ev: any) => {
                            setSubscriptionType(ev.target.checked ? ESubscriptionType.yearly : ESubscriptionType.monthly)
                        }
                        }
                        className="relative w-[3.25rem] h-7 bg-gray-100 checked:bg-none checked:bg-blue-600 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 border border-transparent ring-1 ring-transparent focus:border-blue-600 focus:ring-blue-600 ring-offset-white focus:outline-none appearance-none dark:bg-gray-700 dark:checked:bg-blue-600 dark:focus:ring-offset-gray-800
                        before:inline-block before:w-6 before:h-6 before:bg-white checked:before:bg-blue-200 before:translate-x-0 checked:before:translate-x-full before:shadow before:rounded-full before:transform before:ring-0 before:transition before:ease-in-out before:duration-200 dark:before:bg-gray-400 dark:checked:before:bg-blue-200"/>
                    <label htmlFor="hs-basic-usage" className="sr-only">switch</label>

                    <label className={'ps-2'}>yearly</label>

                </div>

                <div className={'text-center'}>

                    <span>{}</span>


                </div>


            </div>

        </div>
    )

}

export default Subscription